import './ButtonImport.css'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function ButtonImport() {
  const [fields, setFields] = useState([])
  const [searchParams] = useSearchParams()
  const [additionalFields] = useState(searchParams.get('fields'))

  useEffect(() => {
    const defaultColumn = []

    const defaultAdditionalColumns = [
      {
        label: 'Name',
        key: 'name',
        alternateMatches: ['first name', 'first', 'nama'],
        fieldType: {
          type: 'input',
        },
        example: 'Stephanie',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Name is required',
            level: 'error',
          },
        ],
      },
      {
        label: 'Nickname',
        key: 'nickname',
        alternateMatches: ['nickname', 'alternative_name', 'last_name'],
        fieldType: {
          type: 'input',
        },
        example: 'steph',
      },
      {
        label: 'Phone Number',
        key: 'phone_number',
        alternateMatches: ['phone number', 'phone', 'no', 'nomor telepon', 'notel'],
        fieldType: {
          type: 'input',
        },
        example: '62112555',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Phone Number is required',
            level: 'error',
          },
        ],
      },
      {
        label: 'Email',
        key: 'email',
        alternateMatches: ['email'],
        fieldType: {
          type: 'input',
        },
        example: 'example@mail.com',
      },
    ]

    if (additionalFields) {
      additionalFields.split(',').forEach(field => {
        defaultColumn.push({
          label: field,
          key: field,
          alternateMatches: [`${field}`],
          fieldType: {
            type: 'input',
          },
          example: field,
        })
      })

      setFields([...defaultColumn])
    } else {
      setFields([...defaultAdditionalColumns])
    }
  }, [additionalFields])

  const [isOpen, setIsOpen] = useState(true)

  const onSubmit = data => {
    window.parent.postMessage(
      data,
      window.location != window.parent.location ? document.referrer : document.location.href,
    )
  }

  const onClose = e => {
    setIsOpen(false)
  }

  const openImport = e => {
    setIsOpen(true)
  }

  return (
    <div>
      <div className='button-wrap'>
        <button className='button-import' onClick={openImport} style={{}}>
          <span style={{ margin: '10px', color: '#1F979E' }}>Import File (.csv, .xlsx, .xls )</span>
        </button>
      </div>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        fields={fields}
        autoMapHeaders={true}
      />
    </div>
  )
}
