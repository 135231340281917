import './App.css'
import ButtonImport from './components/ButtonImport'
import React from 'react'

function App() {
  return (
    <div className='App App-header'>
      <ButtonImport />
    </div>
  )
}

export default App
